import {
  FOLLOW_VEHICLE,
  UNFOLLOW_VEHICLE,
  GET_FOLLOWED_VEHICLES,
} from 'services/graphql/queries/vehicles-catalogue';
import { useAuthenticatedMutation } from 'services/graphql/hooks';

// Update followed list after follow a vehicle
export const useFollowedVehiclesUpdate = () => {
  return useAuthenticatedMutation(FOLLOW_VEHICLE, {
    update(proxy, response) {
      const data = proxy.readQuery({ query: GET_FOLLOWED_VEHICLES });
      proxy.writeQuery({
        query: GET_FOLLOWED_VEHICLES,
        data: {
          followedVehicles: {
            vehicles: [
              ...data.followedVehicles.vehicles,
              ...response.data.followVehicle.vehicle,
            ],
          },
        },
      });
    },
  });
};

// Update followed list after unfollow a vehicle
export const useUnfollowVehiclesUpdate = salesForceId => {
  return useAuthenticatedMutation(UNFOLLOW_VEHICLE, {
    update(proxy) {
      const data = proxy
        .readQuery({ query: GET_FOLLOWED_VEHICLES })
        .followedVehicles.vehicles.filter(
          vehicle => vehicle.salesForceId !== salesForceId
        );
      proxy.writeQuery({
        query: GET_FOLLOWED_VEHICLES,
        data: {
          followedVehicles: {
            vehicles: data,
          },
        },
      });
    },
  });
};
