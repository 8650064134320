import React from 'react';

const ArrowWithCircle = props => (
  <svg width="26" height="26" viewBox="0 0 26 26" {...props}>
    <g fill="none" fillRule="evenodd" stroke="#000">
      <path
        fill="#1A51A4"
        d="M11.732 8.905c.26 1.26 1.09 1.144 1.092.5l-.002-.048a3.824 3.824 0 00-3.817-3.719 3.827 3.827 0 00-3.818 3.834 3.827 3.827 0 003.719 3.832c.36.038.825-.836-.449-1.092a2.796 2.796 0 01-2.234-2.74 2.79 2.79 0 012.782-2.794 2.79 2.79 0 012.727 2.227z"
      />
      <path
        fill="#1A51A4"
        d="M1.945 9.471c0-3.908 3.167-7.088 7.06-7.088 3.894 0 7.061 3.18 7.061 7.088 0 .36.085.223-.079 1.057-.163.834.975 1.12 1.063.441.087-.68.137-.986.137-1.498 0-4.537-3.662-8.214-8.182-8.214-4.518 0-8.181 3.677-8.181 8.214 0 4.538 3.663 8.215 8.18 8.215.516 0 1.02-.05 1.506-.14.486-.091.486-1.206-.437-1.066-.923.14-.705.08-1.068.08-3.893 0-7.06-3.18-7.06-7.089z"
      />
      <g strokeLinecap="round" strokeLinejoin="round" strokeWidth={2}>
        <path
          fill="#000"
          d="M12.048 11.952L17.047 24l1.775-5.247 5.226-1.782z"
        />
        <path d="M19.684 19.619L24.048 24" />
      </g>
    </g>
  </svg>
);

export default ArrowWithCircle;
